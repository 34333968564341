<template>
    <div class="gallery-page">
        <div id="loader" v-show="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
        </div>
        <div id="voter-registration-form" v-show="showRegForm">
            <div class="overlay">
              <div class="inner-copy">
                <i class="fa-regular fa-circle-xmark" @click="showRegForm = false"></i>
                <h2>
                    Register to participate
                </h2>
                <form @submit="onSubmitVoteForm">
                  <input type="text" v-model="form.full_names" name="name" placeholder="Full names*" class="input-type" required>
                  <input type="email" v-model="form.email" name="email" id="email" placeholder="Email*" class="input-type" required>
                  <input type="tel" v-model="form.phone_number" name="phone" id="phone" placeholder="Phone number*" class="input-type" required>
                  <input type="password" v-model="form.password" name="password" id="password" placeholder="Enter password*" class="input-type" required>
                  <input type="password" v-model="form.password_confirmation" name="cpassword" id="cpassword" placeholder="Confirm password*" class="input-type" required>                  
                  <div class="input-group">
                    <input type="checkbox" id="terms" name="terms" value="Agreed" required>
                    <label for="terms"> By submitting your information you agree to the <a href="#" target="_blank">terms and conditions</a></label>
                  </div>
                  <div class="input-group-btns">
                    <input type="submit" value="COMPLETE" class="input-submit small-btn yellow-btn">
                    <p>
                        ALREADY REGISTERED? <a href="javascript:void(0);" @click="showSignInForm">Sign In</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
        </div>
        <div id="voter-registration-form" v-show="showLogInForm">
            <div class="overlay">
              <div class="inner-copy">
                <i class="fa-regular fa-circle-xmark" @click="showLogInForm = false"></i>
                <h2>
                    Sign In to participate
                </h2>
                <form @submit="onSubmitSignInForm">
                  <input type="email" v-model="signInForm.email" name="email" id="loginemail" placeholder="Email*" class="input-type" required>
                  <input type="password" v-model="signInForm.password" name="password" id="loginpassword" placeholder="Enter password*" class="input-type" required>
                  <div class="input-group-btns">
                    <input type="submit" value="SIGN IN" class="input-submit small-btn yellow-btn">
                    <p>
                        NOT REGISTERED? <a href="javascript:void(0);" @click="showRegistrationForm">Sign Up</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
        </div>
        <div class="inner">
            <div class="top">
                <h4 class="large-title">
                    Vote
                </h4>
                <router-link to="/gallery" class="nav-link-gallery">View all photos</router-link>
            </div>
            <div class="copy">
                <div v-if="image !== null" class="img-wrapper" :style="{ backgroundImage: `url(${uploadsUrl + image.attributes.media_name})` }">
                    <a href="javascript:void(0);" data-fancybox="gallery" :data-src="uploadsUrl + image.attributes.media_name" :data-caption="image.attributes.caption">

                    </a>
                    <div class="vote">
                        <div class="left">
                            <h5 class="small-title">
                                {{ image.attributes.user }}
                            </h5>
                            <p>
                                {{ image.attributes.category }}
                            </p>
                        </div>
                        <div class="right">
                            <h3 @click="votePhoto(image)">
                                {{ image.attributes.votes }} VOTE(S)
                                <i class="fa-solid fa-heart" :style="[ image.attributes.voted ? 'color:rgb(252 204 104);' : 'color: #fff;']"></i>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <VueEternalLoading :load="load"></VueEternalLoading>
            <div class="back-section">
                <a href="javascript:void(0);" @click="redirectToHome">
                    <i class="fa-solid fa-arrow-left"></i> BACK
                </a>
                <div class="img-wrapper">
                    <img src="../assets/imgs/logo.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//Fancybox
import { Fancybox } from "@fancyapps/ui";

//Lazy load
import lozad from 'lozad';

import { defineComponent, ref, getCurrentInstance } from 'vue';
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading';

// const URL = 'https://safaleyeinthewild.tk/app/api/v1/photos';
// const URL = 'https://safaleyeinthewild.safalgroup.com/app/api/v1/photos';
const URL = 'https://safaleyeinthewild.tk/app/api/v1/photos';
// const URL = 'https://staff.safaleyeinthewild.safalgroup.com/app/api/v1/photos';
// const URL = 'http://eyeinthewild.test/api/v1/photos';
const PAGE_SIZE = 6;

export default {
    name: 'IndivualPhotoVoteView',
    components: {
        Fancybox
    },
    data() {
        return {
            // homeUrl:'https://safaleyeinthewild.tk/',
            // baseURL:'https://safaleyeinthewild.tk/app',

            // localBaseURL: 'http://eyeinthewild.test',
            // homeUrl:'http://localhost:8080/#/',
            // baseURL:'http://eyeinthewild.test',
            // uploadsUrl:'http://eyeinthewild.test/storage/photos/2022/',

            //Main
            homeUrl:'https://safaleyeinthewild.safalgroup.com/#/',
            // baseURL:'https://safaleyeinthewild.safalgroup.com/app',            
            // uploadsUrl:'https://safaleyeinthewild.safalgroup.com/competitionapp/storage/app/public/photos/2023/',
            baseURL:'https://safaleyeinthewild.tk/app',        
            uploadsUrl:'https://safaleyeinthewild.tk/competitionapp/storage/app/public/photos/2023/',

            //Staff
            // homeUrl:'https://staff.safaleyeinthewild.safalgroup.com/uat/#/',
            // baseURL:'https://staff.safaleyeinthewild.safalgroup.com/app',            
            // uploadsUrl:'https://staff.safaleyeinthewild.safalgroup.com/competitionapp/storage/app/public/photos/2023/',

            loading: false,
            showRegForm: false,
            showLogInForm: false,
            image:null,
            img_id:'',
            currentPage: 1,
            pageCount: 0,
            token:JSON.parse( localStorage.getItem('token') ),
            user_id:JSON.parse( localStorage.getItem('user_id') ),
            form:{
                full_names:"",
                email:"",
                phone_number:"",
                password:"",
                password_confirmation:"",
            },
            signInForm: {
                email:"",
                password:"",
            }
        }
    },
    methods: {
        redirectToHome(){
            window.location.replace(this.homeUrl);
        },
        async onSubmitSignInForm(e){
            e.preventDefault();

            //Show Loader
            this.showLoadingAlert();

            const res = await fetch(`${this.baseURL}/api/v1/sign-in`,{
                method: 'POST',
                headers:{
                'Accept':'application/json',
                'Content-type':'application/json'
                },
                body:JSON.stringify(this.signInForm)
            });

            const data = await res.json();

            if(data.success){
                //Bind token & id to local storage
                localStorage.setItem( 'token', JSON.stringify(data.data.access_token) );
                localStorage.setItem( 'user_id', JSON.stringify(data.data.id) );
                //Hide Spinner
                this.hideLoadingAlert();
                //Show Success alert
                this.showSuccessAlert('<p>Login successfuly</p>');
                //Clear Form
                this.signInForm.email = '';
                this.signInForm.password = '';

                //Hide reg form
                this.showLogInForm = false;

                this.token = JSON.parse( localStorage.getItem('token') );
                this.user_id = JSON.parse( localStorage.getItem('user_id') );

                //Load images
                window.location.reload();
            }else{
                //Hide Spinner
                this.hideLoadingAlert();
                //Show Error alert
                var errors = '<p>Sorry, incorrect logins!</p>';

                this.showErrorAlert(errors);
            }
        },
        async onSubmitVoteForm(e){
            e.preventDefault();

            //Show Loader
            this.showLoadingAlert();

            const res = await fetch(`${this.baseURL}/api/v1/user-signup`,{
                method: 'POST',
                headers:{
                'Accept':'application/json',
                'Content-type':'application/json'
                },
                body:JSON.stringify(this.form)
            });

            const data = await res.json();

            if(data.success){
                //Bind token & id to local storage
                localStorage.setItem( 'token', JSON.stringify(data.data.access_token) );
                localStorage.setItem( 'user_id', JSON.stringify(data.data.id) );
                //Hide Spinner
                this.hideLoadingAlert();
                //Show Success alert
                this.showSuccessAlert('<p>Registration successfuly</p>');
                //Clear Form
                this.clearForm();
                //Hide reg form
                this.showRegForm = false;

                this.token = JSON.parse( localStorage.getItem('token') );
                this.user_id = JSON.parse( localStorage.getItem('user_id') );
            }else{
                //Hide Spinner
                this.hideLoadingAlert();
                //Show Error alert
                var errors = '';

                data.errors.forEach((err) => {
                errors = errors.concat(" ", `<li style="margin-bottom:0.5em;text-align: left;"><strong>${err.title}</strong> : ${err.details}</li>`);
                });

                this.showErrorAlert(errors);
            }
        },
        async votePhoto(item){
            if(this.token === null || this.token === ''){
                //Hide reg form
                this.showRegForm = true;
                return false;
            }

            //Show Loader
            this.showLoadingAlert();

            const body = {
                'user_id':this.user_id,
                'photo_id':item.id
            }

            const res = await fetch(`${this.baseURL}/api/v1/votes`,{
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-type':'application/json',
                    'Authorization':'Bearer ' + this.token
                },
                body:JSON.stringify(body)
            });

            const data = await res.json();

            if(data.success){
                //Hide Spinner
                this.hideLoadingAlert();
                //Down vote
                if((data.message === 'Voted for photo successfully!')){
                    item.attributes.votes += 1;
                    item.attributes.voted = true;
                    //Show Success alert
                    this.showSuccessAlert('<p>Voted successfuly</p>');
                }else if(data.message === 'Downvote for photo successful!'){
                    item.attributes.votes -= 1;
                    item.attributes.voted = false;
                    //Show Success alert
                    this.showSuccessAlert('<p>Downvote successfuly</p>');
                }
                
            }else{
                //Hide Spinner
                this.hideLoadingAlert();

                //Show Error alert
                var errors = '';

                data.errors.forEach((err) => {
                    errors = errors.concat(" ", `<li style="margin-bottom:0.5em;text-align: left;"><strong>${err.title}</strong> : ${err.details}</li>`);
                });

                this.showErrorAlert(errors);
            }

            // this.showErrorAlert('<p>Sorry, voting has not yet began!</p>');
        },
        showRegistrationForm(){
            this.showRegForm = true;
            this.showLogInForm = false;
        },
        showSignInForm(){
            this.showRegForm = false;
            this.showLogInForm = true;
        },
        clearForm(){
            this.form.full_names = '';
            this.form.email = '';
            this.form.phone_number = '';
            this.form.password = '';
            this.form.password_confirmation = '';
        },
        async loadImage(){

            //Loader
            this.showLoadingAlert();

            const res = await fetch(`${this.baseURL}/api/v1/photos/${this.img_id}`,{
                method: 'GET',
                headers:{
                'Accept':'application/json',
                'Authorization':'Bearer ' + JSON.parse( localStorage.getItem('token') )
                }
            });

            const data = await res.json();

            if(data.success){
                //Set Gallery
                this.image = data.data;
                //Pagination
                //Loader
                this.hideLoadingAlert();
            }else{
                this.showErrorAlert(`<p>Sorry, image could not be loaded. Kindly <a href="${this.homeUrl}gallery">view all photos</a></p>`);
                //Loader
                this.hideLoadingAlert();
            }
        },
        showSuccessAlert(text) {
            // Use sweetalert2
            this.$swal({
                icon: "success",
                html: text,
                closeOnEsc: false,
                closeOnClickOutside: false,
                allowOutsideClick: false,
            });
        },
        showLoadingAlert(){
            this.loading = true;
        },
        hideLoadingAlert(){
            this.loading = false;
        },
        showErrorAlert(text) {
            // Use sweetalert2
            this.$swal({
                icon: 'error',
                title: 'Oops...errors occured!',
                html: text,
                allowOutsideClick: false,
            });
        },
    },
    created(){
        //Get image id
        this.img_id = this.$route.query.img;
        //Fetch image
        this.loadImage();
    }
}
</script>

<style lang="scss" scoped>
    @import "@fancyapps/ui/dist/fancybox.css";
    @import "https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;800&display=swap";
    @import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css";
    @import "../assets/scss/upload.scss";

    html, body #app, .gallery-page{
        height: 100%;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: $yellow;
    }

    ::-webkit-scrollbar-track {
        background: #fff;
    }
</style>