<template>
  <div id="loader" v-show="loading" style="background-color:rgb(247, 240, 217);">
      <i class="fa-solid fa-spinner fa-spin"></i>
  </div>
  <router-view v-if="!loading" id="mainApp"/>
</template>

<script>


// checkIP();

export default {
    name: 'MainView',
    data() {
        return {
          loading: false,
        }
    },
    methods: {
        async checkIp(){
          const ips = [
            "105.163.158.11",
            "102.215.76.97",
            "165.90.23.171",
            "196.216.66.254",
            "165.90.29.167",
            "165.90.27.3",
            "165.90.24.137",
            "165.90.29.181",
            "212.22.165.143",
            "165.90.29.179",
            "212.22.179.118",
            "196.216.70.82",
            "165.90.8.161",
            "165.90.13.171",
            "165.90.23.171",
            "196.216.66.254",
            "165.90.30.3",
            "165.90.29.183",
            "41.191.226.26",
            "165.90.29.239",
            "165.90.19.187",
            "41.162.21.244",
            "105.242.17.218",
            "102.217.134.134",
            "197.155.68.62",
            "197.221.156.158",
            "165.90.27.46",
            "212.22.172.226",
            "41.190.136.18",
            "197.221.156.162",
            "212.22.172.217",
            "154.72.92.58",
            "41.188.173.10",
            "196.41.57.90",
            "155.12.105.18",
            "154.72.70.102",
            "41.188.178.226",
            "196.41.50.162",
            "41.188.152.250",
            "154.72.80.38",
            "41.188.173.38",
            "196.12.131.138",
            "41.242.143.118",
          ];

          // https://api.ipify.org/?format=json
          // https://www.cloudflare.com/cdn-cgi/trace
          // https://checkip.amazonaws.com/

          const res = await fetch('https://www.cloudflare.com/cdn-cgi/trace',{
            // method: "GET", 
            mode: 'cors', 
            // headers: { 'Content-Type': 'application/json',}
          });

          const data = await res.text();

          let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
          let ip = data.match(ipRegex)[0];

          console.log(ip);
          // console.log(data);

          // console.log(res);

          if(!ips.includes(ip)){
            this.loading = true;
            window.location.replace("https://www.safalgroup.com/");
          }else{
            this.loading = false;
          }
          
        },
    },
    mounted(){
      //this.checkIp();
    }
}
</script>

<style lang="scss">
  body{
    margin:0px;
  }
</style>
